import { GenericResultItem } from '@lumapps/search-results/types';
import { SearchSuggestion, SearchSuggestionType } from '@lumapps/search/types';

export type Params = {
    instanceId: string;
    type: SearchSuggestionType;
};

/** Convert a generic item to a search suggestion of the given type */
export const convertGenericItemToSearchSuggestion = (item: GenericResultItem, { instanceId, type }: Params) => ({
    label: item.title,
    query: item.title,
    item: {
        entityId: item.id,
        entityType: item.type,
        lastUsed: '',
        siteId: instanceId,
        title: item.title,
        url: item.item.url || '',
        icon: item.icon,
        thumbnail: item.thumbnail,
        originSiteId: item.originSiteId || instanceId,
        counterClick: 0,
    },
    siteId: instanceId,
    mimeType: item.mimeType,
    type,
    counterClick: 0,
});

/**
 * Map the given generic result items to return an array of search suggestions.
 */
export const mapGenericItemsToSearchSuggestion = (
    items: GenericResultItem[] | null,
    params: Params,
): SearchSuggestion[] => {
    if (!items) {
        return [];
    }

    return items.map((item) => convertGenericItemToSearchSuggestion(item, params));
};
