import { SearchDocumentType, TargetType, TrackingEventType } from '@lumapps/analytics-tracking/types';
import { CommunityPrivacy } from '@lumapps/communities/types';
import { ENTRY_TYPES } from '@lumapps/directories-entries/constants';
import { Tag } from '@lumapps/folksonomy/types';
import { Group } from '@lumapps/groups/types';
import { ExtensionSearchResult } from '@lumapps/marketplace/types';
import { Media } from '@lumapps/medias/types';
import { QuickSearchEntity } from '@lumapps/quick-search/types';
import { AnyAction } from '@lumapps/redux';
import { ThunkAction } from '@lumapps/redux/thunks';
import { FrontOfficeStore } from '@lumapps/redux/types';
import { ExtensionLocalSettings } from '@lumapps/search-settings/types';
import { User } from '@lumapps/user/types';

import { SEARCH_RESULT_TYPES } from './constants';
import { FILTER_TYPES } from './constants/filters';
import { ZENDESK_RESULT_TYPES } from './constants/zendesk';

export enum SearchSuggestionType {
    HISTORY = 'history',
    INTERACTION = 'interaction',
    SUGGESTION = 'suggestion',
    RECOMMENDATION = 'recommendation',
}

export enum SEARCH_CAUSES {
    none = 'none',
    init = 'searchboxSubmit',
    tabChange = 'tabChange',
    queryChange = 'queryChange',
    sortChange = 'resultsSort',
    loadMore = 'loadMore',
    /** A facet has been selected or deselected */
    facetInteracted = 'facetInteracted',
    facetClearAll = 'facetClearAll',
    breadcrumbResetAll = 'breadcrumbResetAll',
    didYouMeanClick = 'didyoumeanClick',
}

export enum DOCUMENT_CAUSES {
    click = 'documentOpen',
    quickView = 'documentQuickview',
}

export enum CUSTOM_CAUSES {
    facetSearch = 'facetSearch',
    pageClick = 'pagerNumber',
    pageNextClick = 'pagerNext',
    pagePreviousClick = 'pagerPrevious',
}

export interface FacetOptions {
    id: string;
    value: string | string[];
}

export interface SearchSuggestion {
    /** suggested query */
    query: string;
    /** query or title with highlighted matches */
    label: string;
    /** the interacted search result */
    item?: QuickSearchEntity;
    /** type of the suggestion */
    type: SearchSuggestionType;
    /** the instance id */
    siteId?: string;
    /** Number of time the suggestion was used */
    counterClick: number;
    /** The mimetype of the search result. Defines the type of result */
    mimeType?: string;
}

export interface SearchActions {
    /** name of the action */
    name: string;
    /** time the action was exacuted */
    time: string;
    /** query used */
    value: string;
}

export interface SearchItem {
    /** identifier of the item */
    id?: string;
    /** Title to display */
    title: string;
    /** URL to be used for the result link */
    url: string;
    /** thumbnail to display */
    thumbnail?: string;
    /** the type of item */
    type: SEARCH_RESULT_TYPES;
    /** icon */
    icon?: string;
    /** site where the item was created */
    siteId?: string;
}

export interface InstanceDetails {
    /** key/value where the key is the language and the value is the translation  */
    name: Record<string, string>;
    /** instance slug not localised */
    slug: string;
    /** instance id */
    id: string;
}

export interface TagsDetails {
    /** key/value where the key is the language and the value is the translation  */
    name: Record<string, string>;
    /** tag id */
    id: number;
}

export interface MetadataDetails {
    /** key/value where the key is the language and the value is the translation  */
    name: Record<string, string>;
    /** metadata parent with name as key/value where the key is the language and the value is the translation  */
    parent: {
        name: Record<string, string>;
    };
}

export interface CustomField {
    /** key/value where the key is the language and the value is the translation  */
    localizedName: Record<string, string>;
    /** custom field name */
    name: string;
    /** custom field order */
    order?: number;
    /** custom field layout */
    layout: number;
}

export interface SearchTab {
    /** filter's type */
    kind: string;
    /** search filter id */
    uid: string;
    /** key/value where the key is the language and the value is the translation  */
    localizedName: Record<string, string> | string;
    /** total amount of results for that filter. Only available when kind = 'lumapps' */
    resultsCount: number;
    /** total number of results without filter */
    totalResultsCount?: number;
    /** list of custom fields */
    customFields?: CustomField[];
    /** custom fields grouped by id in order to create a performant lookup */
    fields?: Record<string, CustomField>;
    /** whether the snippet is visible for the filter */
    isSnippetVisible: boolean;
    /** whether the metadata is visible for the filter */
    isMetadataVisible: boolean;
    /** template to be used for the search tab */
    template: string;
    /** search tab type, meaning that the tab is not a regular filter. could be iframe, external or undefined */
    type?: string;
    /** possible search url if the tab is external or iframe */
    url?: string;
    /** when the filter is an `iframe`, it will give the desired height of the iframe to display */
    height?: string;
    /** should we add the token of the user as a query parameter in the given url */
    sendUserIdentity?: boolean;
    /** Extension Id */
    extensionId?: string;
    /** Connector id for the extension */
    connectorId?: string;
    /** Local settings of the extension */
    extensionSettings?: ExtensionLocalSettings;
}

export interface CustomSearchTab {
    /** possible search url if the tab is external or iframe */
    url: string;
    /** search tab type, meaning that the tab is not a regular filter. could be iframe, external or undefined */
    type: FILTER_TYPES;
    /** search filter id */
    id: string;
    /** localised label */
    name: Record<string, string> | string;
}

/** Backend field */
export interface BackendSearchSort {
    /** key/value where the key is the language and the value is the translation */
    localizedOperatorName: Record<string, string>;
    /** sort id */
    operatorName: string;
}

export type SearchSort = {
    label: Record<string, string>;
    value: string;
};

export interface Facet {
    /** facet id */
    operatorName: string;
    /** facet field */
    field: string;
    /** key/value where the key is the language and the value is the translation */
    localizedOperatorName: Record<string, string>;
    /** whether this facet can have multiple values or not */
    isMultiple?: boolean;
    /** list of choices for the facet */
    buckets: {
        /** total amount of results */
        count: number;
        /** key/value where the key is the language and the value is the translation */
        label: Record<string, string>;
        /** facet value */
        value: string;
        /** whether the facet is selected or not */
        selected: boolean;
    }[];
    /** Is the facet closed by default */
    isCollapsed?: boolean;
    /** Should it be displayed hierarchicaly */
    isHierarchical?: boolean;
    /** should we display all choices? */
    shouldDisplayAllValues?: boolean;
}

export type SearchFilter = {
    /** key/value where the key is the language and the value is the translation */
    label: string | Record<string, string>;
    /** search filter value */
    value: string;
    /** total results for the filter */
    count?: number;
    /** total results for the filter for the query without facet */
    totalCount?: number;
    /** filter UI type, usually undefined but it can be `external` or `iframe` */
    type?: FILTER_TYPES;
    /** possible search url if the tab is external or iframe */
    url?: string;
    /** when the filter is an `iframe`, it will give the desired height of the iframe to display */
    height?: string;
    /** should we add the token of the user as a query parameter in the given url */
    sendUserIdentity?: boolean;
    /** info computed by the front to display more info about the filter */
    helper?: string;
};

export type SearchFilterExtension = Pick<SearchFilter, 'value'> & {
    label: string | Record<string, string>;
    type: FILTER_TYPES.EXTENSION;
    extensionId: string;
    connectorId?: string;
    extensionSettings?: ExtensionLocalSettings;
};

export type FacetFilter = {
    /** key/value where the key is the language and the value is the translation */
    label: string | Record<string, string>;
    /** search facet value */
    value?: SearchFilter | SearchFilter[];
    /** list of choices for the facet */
    choices: SearchFilter[];
    /** facet id */
    id: string;
    /** facet field */
    field: string;
    /** is collapsed by default (for the side filters) */
    isCollapsed?: boolean;
    /** is hierarchical */
    isHierarchical?: boolean;
    /** whether this facet can have multiple values or not */
    isMultiple?: boolean;
    /** should we display all choices? */
    shouldDisplayAllValues: boolean;
};

export interface AuthorDetails {
    /** author's first name */
    firstName: string;
    /** author's last name */
    lastName: string;
    /** author's full name */
    fullName: string;
    /** author's id */
    id: string;
    /** customer id of the platform */
    customerId: string;
    /** customer id of the platform, yes, sometimes it comes as customerId and sometimes it is customer */
    customer?: string;
    /** author's email */
    email: string;
}

export interface ParentContentDetails {
    /** parent id */
    id: string;
    /** parent instance */
    instance: string;
    /** parent slug */
    slug: string;
    /** parent uid */
    uid: string;
    /** key/value where the key is the language and the value is the translation */
    title: Record<string, string>;
}

export interface ContentSearchResult {
    /** details of the instance related to the content */
    instanceDetails: InstanceDetails;
    /** content's author */
    authorDetails: AuthorDetails;
    /** content's metadata */
    metadataDetails: MetadataDetails[];
    /** total amount of likes that the content has */
    likes: number;
    /** total amount of comments that the content has */
    comments: number;
    /** article's thumbnail */
    thumbnail: string;
    /** total amount of attachments that the content has */
    attachmentsCount?: number;
    /** score that the content has if it has a voting system */
    score?: {
        total: number;
    };
    /** content's url */
    url: string;
    /** content id */
    uid: string;
    /** is the content an external link */
    isExternalLink?: boolean;
    /** content slug */
    slug?: Record<string, string>;
    /** is the content promoted? */
    isPromoted?: boolean;
}

export interface ArticleSearchResult extends ContentSearchResult {
    /** icon for custom content type */
    customContentTypeDetails?: { icon: string; name: Record<string, string> };
}

export interface PostSearchResult extends ContentSearchResult {
    /** the post's list of tagz */
    tagz: Tag[];
    tagsDetails?: TagsDetails[];
    parentContentDetails?: ParentContentDetails;
}

export interface CommunitySearchResult {
    /** community's instance */
    instanceDetails: InstanceDetails;
    /** community's thumbnail */
    thumbnail: string;
    /** list of details for the first members of the community */
    members: AuthorDetails[];
    /** community url */
    url: string;
    /** id */
    uid: string;
    privacy: CommunityPrivacy;
}

export interface DirectoryEntrySearchResult {
    id: string;
    /** directory entry's instance */
    instanceDetails: InstanceDetails;
    /** directory entry's metadata */
    metadataDetails: MetadataDetails[];
    /** directory entry's url */
    link: string;
    /** directory entry's thumbnail */
    thumbnail: string;
    /** directory entry's name */
    name: string;
    /** directory entry's type */
    directoryEntryType: ENTRY_TYPES;
}

export interface UserSearchResult extends User {
    /** customer id related to the user result */
    customer: string;
    /** user's url */
    url: string;
}

export interface MediaSearchResult {
    /** Media id */
    id: string;
    uid?: string;
    /** media author's details */
    authorDetails: AuthorDetails;
    /** media instance's details */
    instanceDetails: InstanceDetails;
    /** media's thumbnail */
    thumbnail: string;
    /** media's url */
    url: string;
    /** media's title */
    title: string;
    /** media's mime type */
    mimeType: string;
}

type GenericDocumentResultStringField = { id: string; value: string; format: 'string' };
type GenericDocumentResultJsonField = { id: string; value: Record<string, string>; format: 'json' };
type GenericDocumentResultField = GenericDocumentResultStringField | GenericDocumentResultJsonField;

export interface GenericDocumentResult {
    /** mime type */
    mimeType: string;
    language?: string;
    author: string;
    thumbnail?: string;
    source: string;
    id: string;
    metadata?: string[];
    // Debug prop used to add some border to the result type
    color?: string;
    // Debug prop used to display additional info
    fields?: GenericDocumentResultField[];
}

export interface Field {
    name: string;
    lokalizedName?: Record<string, string>;
    textValues?: {
        values: string[];
    };
    dateValues?: {
        values: {
            day: string;
            month: string;
            year: string;
        }[];
    };
    integerValues?: {
        values: string[];
    };
}

export type SearchResultsCustomFields = {
    id: string;
    iconClass: string;
    value?: string;
};

export type UserResultField = {
    key: string;
    value: string | undefined;
    icon: string;
};

export interface SearchResultAttachment {
    title: string;
}

/** Search result that has (at least) a url and title field */
export type SearchResultWithTitleAndUrl = {
    /** full result's url, should be used as a fallback if there is no specific url for the result */
    url: string;
    /** results title */
    title: string;
    /** Optional result id */
    id?: string;
    /** Optional document type */
    type?: TargetType;
    /** Optional document position in the results */
    position?: number;
};

export interface BaseSearchResult extends SearchResultWithTitleAndUrl {
    /** result's metadata */
    metadata: {
        updateTime: string;
        createTime: string;
        sourceUid: string;
        fields?: Field[];
        thumbnailUrl?: string;
        mimeType?: string;
        sourceName?: string;
        documentUri?: string;
        documentUriHash?: string;
        externalId?: string;
        contentIDKey?: string;
        contentIDValue?: string;
        collectionName?: string;
        rankingModifier?: string;
    };
    /** snippet for the result */
    snippet?: Record<string, string> | string;
    article?: ArticleSearchResult;
    community?: CommunitySearchResult;
    directoryEntry?: DirectoryEntrySearchResult;
    user?: UserSearchResult;
    media?: MediaSearchResult;
    post?: PostSearchResult;
    genericDocument?: GenericDocumentResult;
    id?: string;
    // The used sort
    sort?: string;
    position?: number;
    isPromoted?: boolean;
    customFields?: SearchResultsCustomFields[];
    /** Should the result has the featured chip? */
    isTopResult?: boolean;
    quickViewUrl?: string;
    /** Should the result has the recommended chip? */
    isRecommendation?: boolean;
    /** the entity's list of tagz */
    tagz?: Tag[];
    thumbnail?: string;
    attachments?: Array<SearchResultAttachment>;
}

export interface DriveSearchResult {
    embedLink: string;
    id: string;
    isFolder: boolean;
    lastUpdate: string;
    link: string;
    mimeType: string;
    title: string;
}

export interface LumworkSearchResult {
    creationDate: string;
    id: string;
    lastUpdate: string;
    name: Record<string, string>;
    spaceId: string;
    version: string;
    position?: number;
}

export interface ZendeskSearchResult {
    status?: string;
    url: string;
    updatedAt: string;
    type: ZENDESK_RESULT_TYPES;
    id: number;
    subject: string;
    position?: number;
}

export interface SharePointSearchResult extends Media {
    updatedAt: string;
    createdAt: string;
    uuid: string;
    uid: string;
    name?: Record<string, any>;
    properties?: {
        type: string;
    };
}

export interface PromotedResults {
    items: BaseSearchResult[];
}

/**
 * This is a generic base Result that allows manipulating results as one, rather than
 * doing specific logic for each type of result. This adds a layer of abstraction between
 * the APIs and the UI components
 */
export type SearchResult =
    | ZendeskSearchResult
    | LumworkSearchResult
    | DriveSearchResult
    | BaseSearchResult
    | SharePointSearchResult
    | ExtensionSearchResult;

export interface SearchParams {
    /** query to search */
    query: string;
    /** total number of results to display */
    maxResults?: number;
    /** the current instance id */
    instanceId?: string;
    /** lang to search */
    lang?: string;
    /** filter to apply when searching */
    filter?: string;
    /** facets to apply mapped as key = facet id, and value = facet value  */
    facets?: Record<string, string>;
    /** cursor to use when calling the API */
    cursor?: string;
    /** list of features enabled while executing the search */
    // eslint-disable-next-line no-use-before-define
    features?: FeaturesStatus;
    /** sort id to apply */
    sort?: string;
    /** timeout in ms to use when calling the API */
    timeout?: number;
    /** the tab config */
    config?: any;
    /** should we skip group control */
    useGlobalAdminPower?: boolean;
    /** page to search */
    page?: number;
    /** format to use. default empty. Set to xlsx to download the page */
    format?: string;
    /** context of the query. Like user data. Used for analytics purpose */
    context?: Record<string, string | string[]>;
}

export interface SuggestedQuery {
    suggestedQuery: string;
    isPromoted?: boolean;
}

export interface ThirdPartyCustomField {
    localizedName: Record<string, string>;
    layout: number;
    path: string;
}

export interface ThirdPartyFiltersTemplate {
    type: string;
    id: string;
    fields: ThirdPartyCustomField[];
    isMetadataVisible?: boolean;
    isSnippetVisible?: boolean;
}

export interface SearchResults {
    /** items that resulted from the executed search */
    items: SearchResult[];
    /** items that resulted from the executed search */
    promoted?: PromotedResults | null;
    /** list of filters for the executed search */
    filters?: SearchFilter[];
    /** list of sorts for the executed search */
    sorts?: SearchSort[];
    /** metadata related to the filters of the search */
    metadata?: Record<string, SearchTab>;
    /** list of facets for the current search */
    facets?: FacetFilter[];
    /** whether there are more results or not */
    more: boolean;
    /** cursor to be used in order to search for more results */
    cursor?: string;
    /** List of suggested queries */
    spellResults?: SuggestedQuery[];
    /** number of results */
    resultCountExact?: number;
    /** page */
    page?: number;
    /** Query response time. Used for analytics purpose */
    responseTime?: string;
    /** Identifier of the query. Used for analytics purpose */
    searchQueryUid?: string;
    /** A/B testing fields for Coveo */
    splitTestRunName?: string;
    splitTestRunVersion?: string;
    templates?: ThirdPartyFiltersTemplate[];
    engine?: string;
    responseStatus?: string;
    sortOrders?: BackendSearchSort[];
}

export interface SearchAPIResponse extends SearchResults {
    /** Whether we want to append the results to the current ones or we want to start over */
    appendResults?: boolean;
    /** selected sort id */
    sort?: string;
    /** query entered by the user */
    query: string;
    /** selected filter id */
    filter?: string;
    /** should we use previous choices of the selected facet? */
    shouldUseFacetChoicesHistory: boolean;
}

export type SearchExternalAPIResponse = Pick<SearchResults, 'items' | 'cursor' | 'facets' | 'more' | 'filters'> & {
    appendResults: boolean;
};

export interface SearchSuggestionParams {
    /** query to search */
    query: string;
    /** list of features enabled while executing the search */
    features?: Record<string, boolean>;
    nbQueries?: number;
    nbResults?: number;
    /** The current site id */
    siteId?: string;
    /** The languages to set as Accept-Language header */
    acceptLanguage?: string;
}

export type SearchQuerySuggestion = {
    query: string;
};

/**
 * Specific response from coveo suggest
 */
export type SearchSuggestionResponse = {
    queries: SearchQuerySuggestion[];
    results: BaseSearchResult[];
};

export type TrackSearchData = {
    language: string;
    // eslint-disable-next-line no-use-before-define
    actionCause: SEARCH_CAUSES | FacetInteraction;
    queryText: string;
    actionsHistory?: SearchActions[];
    responseTime?: string;
    searchQueryUid?: string;
    numberOfResults: number;
    pageNumber?: number;
    customData?: Record<string, any>;
    context?: Record<string, string>;
    splitTestRunName?: string;
    splitTestRunVersion?: string;
    originLevel2?: string;
};

export type TrackCustomEventData = {
    actionCause: CUSTOM_CAUSES;
    language: string;
    customData?: Record<string, any>;
};

export type TrackDocumentData = {
    /** Current language of the user */
    language: string;
    /** Did we open or click on the result? */
    actionCause: DOCUMENT_CAUSES;
    actionsHistory: SearchActions[];
    documentUriHash?: string;
    documentTitle: string;
    documentUrl?: string;
    collectionName?: string;
    /** Author fullname of the document */
    documentAuthor?: string;
    documentUri?: string;
    /** Position in the result list */
    documentPosition?: number;
    sourceName?: string;
    /** Identifier of the search query that has returned the result */
    searchQueryUid?: string;
    rankingModifier?: string;
    customData?: Record<string, any>;
    context?: Record<string, string>;
    splitTestRunName?: string;
    splitTestRunVersion?: string;
    originLevel2?: string;
};

/**
 * Used to know if a feature is active or not
 */
export interface FeaturesStatus {
    isNSEnabled: boolean;
    isCoveoEnabled: boolean;
    isSharePointBetaEnabled: boolean;
    areAnalyticsEnabled: boolean;
    isRecommendationEnabled: boolean;
    isTagzEnabled: boolean;
    isHideEmailEnabled: boolean;
    areImprovementsEnabled: boolean;
}

export enum FacetInteraction {
    SELECT = 'facetSelect',
    DESELECT = 'facetDeselect',
}

/**
 * Redux Thunk is the standard middleware for writing sync and async logic that interacts with the Redux store.
 * A thunk function receives dispatch and getState as its parameters.
 */
export type Thunk<ReturnType = void, ExtraArgument = unknown> = ThunkAction<
    ReturnType,
    FrontOfficeStore,
    ExtraArgument,
    AnyAction
>;

export interface FetchResultsParams {
    /** search query */
    query: string;
    /** filter to apply */
    filter?: string;
    /** facets to apply */
    currentFacets?: Record<string, string>;
    /** Whether this is a retrieval of the next page */
    moreResults?: boolean;
    /** sort to apply */
    sort?: string;
    /** current page */
    page?: number;
    /** format to use. default empty. Set to xlsx to download the page */
    format?: string;
    /** context of the query. Like user data. Used for analytics purpose */
    context?: Record<string, string | string[]>;
    /** The traceID to set as header of this query */
    traceId: string;
    /** Type of filter set */
    filterType?: FILTER_TYPES;
}

export type SearchGroup = {
    id: string;
    name: string;
};

export type SearchGroupOrFeed = SearchGroup | Group;

export type SelectedFacets = Record<string, string[] | string>;

export type SearchEvent = {
    traceId: string;
    splitTestRunName?: any;
    splitTestRunVersion?: any;
    currentLanguage: string;
    actionCause: SEARCH_CAUSES | FacetInteraction;
    resultCountExact: number;
    query: string;
    currentPage: number;
    responseTime?: string;
    searchQueryUid?: string;
    context?: any;
    sort?: string;
    currentFacets: SelectedFacets;
    engine: string;
    extensionId?: string;
    actionsHistory?: SearchActions[];
    customData?: any;
    filter?: string;
    promotedIds: string[];
    displayedResults?: SearchResultWithTitleAndUrl[];
};

export type SearchClickEvent = {
    traceId: string;
    position?: number;
    isPromoted?: boolean;
    query: string;
    targetType: SearchDocumentType;
    targetId?: string;
    resultCount?: number;
    tabId?: string;
    additionalInformation: Record<string, string>;
    collectionName?: string;
    documentTitle: string;
    documentUri?: string;
    documentUrl: string;
    documentUriHash?: string;
    language: string;
    sourceName?: string;
    searchQueryUid: string;
    rankingModifier?: string;
    contentIDKey?: string;
    contentIDValue?: string;
    context: Record<string, string>;
    actionsHistory: SearchActions[];
    type: TrackingEventType;
    actionCause: DOCUMENT_CAUSES;
    thumbnail?: string;
    extensionId?: string;
    searchEngine: string | null;
};

export type ExtensionSearchResultWithPromoted = ExtensionSearchResult & { isPromoted?: boolean };

export enum SearchType {
    SEARCH_SEMANTIC = 'semantic_search',
    SEARCH = 'search',
}
