import React from 'react';

import { ComboboxOption } from '@lumapps/combobox/components/ComboboxOption';
import { mdiClockOutline } from '@lumapps/lumx/icons';
import { Icon, Size } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { QUICK_SEARCH } from '../../keys';
import { SearchBoxOptionLabel } from '../SearchBoxOptionLabel';
import { SearchBoxOptionTypeProps } from './types';

/**
 * Component to display a "history" type option
 * These option have a specific icon and redirects to the search page.
 */
export const SearchHistoryOption = ({ suggestion, ...forwardedProps }: SearchBoxOptionTypeProps) => {
    const { translateKey } = useTranslate();

    return (
        <ComboboxOption
            textValue={suggestion.label}
            before={
                <Icon
                    alt={translateKey(QUICK_SEARCH.HISTORY_ITEM)}
                    icon={mdiClockOutline}
                    hasShape={false}
                    size={Size.s}
                />
            }
            data={suggestion}
            {...forwardedProps}
        >
            <SearchBoxOptionLabel label={suggestion.label} />
        </ComboboxOption>
    );
};
